export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

// meterial icon find here (sample)
// https://mui.com/material-ui/material-icons/
export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.banners',
  },
  routes: [
    {
      name: 'banners',
      displayName: 'menu.banners',
      meta: {
        icon: 'reorder',
      },
    },
    {
      name: 'categorys',
      displayName: 'menu.categorys',
      meta: {
        icon: 'reorder',
      },
    },
    {
      name: 'services',
      displayName: 'menu.services',
      meta: {
        icon: 'reorder',
      },
    },
    {
      name: 'items',
      displayName: 'menu.items',
      meta: {
        icon: 'reorder',
      },
    },
    {
      name: 'itemsOnSale',
      displayName: 'menu.itemsOnSale',
      meta: {
        icon: 'reorder',
      },
    },
    {
      name: 'articles',
      displayName: 'menu.articles',
      meta: {
        icon: 'store',
      },
    },
    // {
    //   name: 'testimonials',
    //   displayName: 'menu.testimonials',
    //   meta: {
    //     icon: 'reorder',
    //   },
    // },
    // {
    //   name: 'galleries',
    //   displayName: 'menu.galleries',
    //   meta: {
    //     icon: 'store',
    //   },
    // },
    // {
    //   name: 'jobs',
    //   displayName: 'menu.jobs',
    //   meta: {
    //     icon: 'reorder',
    //   },
    // },
  ] as INavigationRoute[],
}
