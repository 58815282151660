import { getFirestore } from 'firebase/firestore'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: 'AIzaSyBvtjJkUzWZV0HwGUPBO7Hv5MEnYt9xWiQ',
//   authDomain: 'hkl-shop.firebaseapp.com',
//   projectId: 'hkl-shop',
//   storageBucket: 'hkl-shop.appspot.com',
//   messagingSenderId: '739341841610',
//   appId: '1:739341841610:web:24d8ff3bbac61f8447b033',
// }

const firebaseConfig = {
  apiKey: 'AIzaSyA4xojobpFpWeSE_DZVoJV-6vTzcEAoWqg',
  authDomain: 'fir-website-ff358.firebaseapp.com',
  projectId: 'fir-website-ff358',
  storageBucket: 'fir-website-ff358.appspot.com',
  messagingSenderId: '274002100342',
  appId: '1:274002100342:web:29a0f4649bbd341f231f57',
  measurementId: 'G-D641RJ3TM0',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

// Convert Firestore Images
const firestoreConvertToImage = (link: any) => {
  const storeUrl = encodeURIComponent(link)
  return `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${storeUrl}?alt=media`
}

export { db, app, firestoreConvertToImage }
